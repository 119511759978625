import React from 'react'
import { Box } from 'theme-ui'
import { HeaderLogo } from '../Header/Header.Logo'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const styles = {
  logo: {
    pb: 1,
    mb: 2,
    mt: [4, 0],
    mr: 4
  },
  copyright: {
    pt: 2,
    mb: [2, 4]
  }
}

export const FooterLogo = () => {
  const { description } = useSiteMetadata()
  return (
    <>
      <Box sx={styles.logo}>
        <HeaderLogo grayscale />
      </Box>
      <Box sx={styles.logo}>{ description }</Box>
      <Box sx={styles.copyright}>© {new Date().getFullYear()}, All Rights Reserved.</Box>
      {/* <Box>
      <PoweredByGatsby />
    </Box> */}
    </>
  )
}
