import amzLogo from './amzLogo.svg'

export default {
  /** Root (body tag) */
  root: {
    WebkitFontSmoothing: `antialiased`,
    textDecoration: `none`
  },

  /** MDX articles */
  pre: {
    variant: `prism`,
    fontFamily: `monospace`,
    hyphens: `none`,
    overflow: `auto`,
    borderRadius: `default`,
    tabSize: 4,
    p: 3,
    my: 4
  },
  inlineCode: {
    borderRadius: `0.3em`,
    color: `#4a5568`,
    bg: `highlight`,
    py: 1,
    px: 2
  },
  a: {
    variant: `links.normal`
  },
  h1: {
    variant: `text.h1`,
    borderBottomWidth: `sm`,
    borderBottomStyle: `solid`,
    borderBottomColor: `omegaLight`,
    pb: 3,
    mb: 4
  },
  h2: {
    variant: `text.h2`
  },
  h3: {
    variant: `text.h3`,
    '& + pre': {
      mt: 0
    }
  },
  h4: {
    variant: `text.h4`,
    '& + pre': {
      mt: 0
    }
  },
  h5: {
    variant: `text.h5`
  },
  h6: {
    variant: `text.h6`
  },
  hr: {
    border: '0',
    height: '0',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    my: 4,
    color: `omegaLight`
  },
  img: {
    width: '100%',
    maxWidth: 400,
    maxHeight: 400,
    objectFit: 'contain',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '0.5rem'
  },
  p: {
    color: 'article',
    fontSize: 3,
    mb: 4,
    code: {
      fontSize: `inherit`
    }
  },
  ol: {
    mb: 4
  },
  ul: {
    mb: 4
  },
  li: {
    color: 'article',
    fontSize: 3,
    code: {
      fontSize: `inherit`
    },
    m: 0
  },
  table: {
    marginTop: ['-2rem', 'auto'],
    marginLeft: ['-2rem', 'auto'],
    marginRight: ['-2rem', 'auto'],
    marginBottom: '4rem',
    width: ['auto', '100%'],
    borderRadius: `default`,
    overflow: `hidden`,
    bg: `omega`,
  },
  tr: {
    color: `omegaDarker`,
    '&:nth-of-type(even)': {
      bg: `omegaLight`
    }
  },
  th: {
    whiteSpace: 'nowrap',
    wordBreak: 'keep',
    bg: `omegaDarker`,
    color: `omegaLighter`,
    fontWeight: `normal`,
    p: 2,
    '&:first-of-type': {
      pl: 3
    },
    '&:last-of-type': {
      pr: 3
    },
  },
  td: {
    borderBottomWidth: 0,
    p: 2,
    '&:first-of-type': {
      pl: 3
    },
    '&:last-of-type': {
      pr: 3
    },
    img: {
      maxWidth: 100,
      width: '100%',
      borderRadius:'0.5rem'
    }
  },
  blockquote: {
    p: {
      color: `text`
    },
    fontStyle: `italic`,
    borderLeftStyle: `solid`,
    borderLeftColor: `alphaLight`,
    borderLeftWidth: `md`,
    mx: [0, 4],
    px: [3, 4],
    '&.translation': {
      fontSize: 2
    }
  },
  figure: {
    boxShadow: `1px 1px 5px 0 rgba(1,1,1,.15)`,
    overflow: `hidden`,
    backgroundColor: `contentBg`,
    borderRadius: 'default',
    margin: 0
  },
  figcaption: {
    textAlign: `center`,
    fontStyle: `italic`,
    fontSize: 1,
    margin: 3
  },
  checkPriceButton: {
    wordBreak: 'keep-all',
    boxSizing: 'border-box',
    display: 'inline-block',
    padding: '7px 12px 6px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
    textAlign: 'center',
    borderRadius: '0.5rem',
    boxShadow: '0 1px 2px rgba(0,0,0,.3),inset 0 0 40px rgba(0,0,0,.1)',
    backgroundColor: '#f39c12',
    color: '#fff !important',
    position: 'relative',
    paddingLeft: '32px',
    '&:before': {
      backgroundImage: `url(${amzLogo})`,
      position: 'absolute',
      content: '""',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '14px 14px',
      backgroundPosition: '9px'
    },

    '&:hover': {
      WebkitAnimation: 'scale 0.1s linear',
      MozAnimation: 'scale 0.1s linear',
      animation: 'scale 0.1s linear',
      transformOrigin: '50% 50%',
      animationFillMode: 'forwards'
    }
  },
  buyBox: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 500,
    paddingLeft: 'auto',
    padding: '1rem 4rem',
    fontSize: 19
  }
}
