export default {
  html: {
    fontSize: [`85%`, `90%`, `95%`, `100%`]
  },
  '::selection': {
    color: t => t.colors.white,
    background: t => t.colors.omegaDark
  },
  '::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    borderRadius: '5px',
    backgroundColor: '#F5F5F5'
  },
  '::-webkit-scrollbar': { width: '10px', height:"5px", backgroundColor: '#F5F5F5' },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
    backgroundColor: 'rgba(161, 156, 168, 0.31)'
  }
}
